import kitchen from "../../assets/services/commercial kitchen2.jpg";
import stainlessSteelTable from "../../assets/services/stainless Steel Table.jpg";
import Consultation from "../../components/Consultation";
import Footer from "../../components/Footer";
import ServicesPage from "../../components/ServicesPage";


const CommercialKitchen = () => {
  const services = [
    {
      text: `Black Smith has developed a reputation for high quality custom and
            standard stainless steel cabinet for residential and commercial use.
            We specialized in wall cabinet, base cabinet & solid wall shelf.`,
      title: ` Stainless Steel Cabinet`,
      img: kitchen,
    },
    {
      text: `We provide commercial grade stainless steel tables like work table,
            work table with base cabinet, work table with drawer and outlet
            table.`,
      title: ` Stainless Steel Table`,
      img: stainlessSteelTable,
    },
  ];
  return (
    <>
      <ServicesPage services={services} />
      <Consultation />
      <Footer/>
    </>
  );
};

export default CommercialKitchen;
