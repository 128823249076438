import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect,useState } from "react";
import { BsFillCaretLeftSquareFill } from "react-icons/bs";
import { ScrollRestoration } from "react-router-dom";
const ServicesPage = ({services}) => {
  const [show,setShow]=useState(BsFillCaretLeftSquareFill)
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      const animation = gsap.to(".photo:not(:first-child)", {
        opacity: 1,
        scale: 1,
        duration: 1,
        stagger: 1,
      });
      ScrollTrigger.create({
        trigger: `.service-page`,
        start: `top top`,
        end: `bottom bottom`,
        pin: ".right",
        animation: animation,
        scrub: true,
        onLeave: () => setShow(true),
      });
    });
    return () => ctx.revert();
  }, []);
  return (
    <section className="service-page max-w-[100vw] h-full relative flex  flex-col lg:flex-row overflow-hidden">
      <ScrollRestoration />
      <div className="left w-[100vw] lg:w-[50%] ">
        {services.map((service) => (
          <div key={service.title}>
            <div
              key={service.title}
              className=" h-[100vh] flex flex-col mx-auto justify-center w-full lg:w-[40vw]"
            >
              <img
                src={service.img}
                className="w-[100vw] h-[50%] lg:hidden"
                alt={service.title}
              />
              <div className="my-auto px-[20px] lg:px-0">
                <h1 className="text-primary-orange text-[20px] md:text-[35px] font-bold font-grotesk mb-[1rem]">
                  {service.title}
                </h1>
                <p className=" text-[18px] md:text-[24px] font-semibold font-grotesk">
                  {service.text}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="right relative w-[50%] h-[100vh] flex flex-col justify-center hidden lg:block">
        {services.map((service) => (
          <div key={service.title} className="photo absolute w-full h-full  ">
            <img
              src={service.img}
              alt={service.title}
              className="w-full h-full object-fill  "
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesPage;
