import { Link } from "react-router-dom";
import steelFabrication from "../assets/p-l-img-1.jpg";
import interiorWork from "../assets/interior-work.jpg";
import architectural from "../assets/architectural.jpg";
import kitchen from "../assets/services/commercial kitchen.jpg";
import dumbwaiter from "../assets/services/Dumbwaiter.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import Consultation from "../components/Consultation";
import Footer from "../components/Footer";
import { ScrollRestoration } from "react-router-dom";

const Services = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: `.gsap-services`,
          start: `top center`,
          end: `bottom center`,
        },
      });
      tl.from(`.gsap-first-service`, {
        opacity: 0,
        yPercent: 50,
        stagger: 0.05,
        ease: `power4.out`,
      })
        .from(`.gsap-second-service`, {
          opacity: 0,
          yPercent: 50,
          stagger: 0.05,

          ease: `power4.out`,
        })
        .from(`.gsap-third-service`, {
          opacity: 0,
          yPercent: 50,
          stagger: 0.05,

          ease: `power4.out`,
        })
        .from(`.gsap-fourth-service`, {
          opacity: 0,
          yPercent: 50,
          stagger: 0.05,

          ease: `power4.out`,
        })
        .from(`.gsap-fifth-service`, {
          opacity: 0,
          yPercent: 50,
          stagger: 0.05,

          ease: `power4.out`,
        });
    });
    return () => ctx.revert();
  }, []);
  const links = [
    {
      images: steelFabrication,
      descrition: `STEEL FABRICATION WORK`,
      link: `steel-fabrication-work`,
      gsap: `gsap-first-service`,
    },
    {
      images: dumbwaiter,
      descrition: `DUMBWAITER, MACHINERY AND EQUIPMENT`,
      link: `dumbwaiter-machinery-and-equipment`,
      gsap: `gsap-fourth-service`,
    },

    {
      images: architectural,
      descrition: `ARCHITECTURAL METAL WORK`,
      link: `architectural-metal-work`,
      gsap: `gsap-second-service`,
    },

    {
      images: kitchen,
      descrition: `COMMERCIAL KITCHEN EQUIPMENT AND HEALTH CARE PROVIDERS SS EQUIPMENT`,
      link: `commercial-kitchen-equipment-and-health-care-providers-ss-equipment`,
      gsap: `gsap-fifth-service`,
    },
    {
      images: interiorWork,
      descrition: `INTERIOR WORKS`,
      link: `interior-works`,
      gsap: `gsap-third-service`,
    },
  ];
  return (
    <>
      <section className="max-w-[100vw] h-full ">
        <ScrollRestoration/>
        <div className="w-full  flex items-center justify-center my-8 ">
          <h1 className="text-[35px] text-primary-orange font-extrabold font-grotesk">
            Our Services
          </h1>
        </div>
        <div className="max-w-[100vw] lg:grid xl:grid-rows-2 lg:grid-rows-3 md:flex md:flex-col md-items-center md:justify-center lg:grid-flow-col justify-center gap-8 mb-8 ">
          {links.map((link) => (
            <div
              key={link.descrition}
              className={`${link.gsap} mx-auto flex flex-col items-center md:w-[400px] h-full gap-4 group z-10 cursor-pointer `}
            >
              <Link
                to={link.link}
                className="w-[300px] h-[400px] md:w-[400px] md:h-[600px] overflow-hidden"
              >
                <img
                  src={link.images}
                  alt={link.descrition}
                  className="object-cover w-full h-full group-hover:scale-110 transition-all ease-out duration-500 "
                />
              </Link>

              <Link to={`${link.link}`} className=" text-center">
                <span className=" text-[25px] font-bold font-grotesk group-hover:text-primary-orange bg-gradient-to-r from-primary-orange to-primary-orange bg-[length:0_2px] bg-no-repeat bg-left-bottom group-hover:bg-[length:100%_2px] transition-all duration-[400ms] ease">
                  {link.descrition}
                </span>
              </Link>
            </div>
          ))}
        </div>
        <Consultation />
        <Footer />
      </section>
    </>
  );
};

export default Services;
