import React from "react";
import {Link,Outlet} from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";

import logo1 from "../assets/logo.png";
const NavBar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <header className=" relative max-w-[100vw] bg-white h-[100px] flex items-center justify-between border-b border-black  ">
        <Link to="/">
          <img
            src={logo1}
            alt="blacksmith-logo"
            className="w-[150px] md:w-[200px] h-[80px] pl-3 pb-4 `  "
          />
        </Link>
        <div className="flex h-full items-center gap-9">
          <nav className="text-[20px] font-grotesk flex gap-9 font-bold ">
            <Link to="/about-us" className="link-animation">
              About
            </Link>
            <Link to="/services" className="link-animation ">
              Services
            </Link>
            <Link to="/contact-us" className="link-animation ">
              Contact
            </Link>
          </nav>
          <div
            className={`relative justify-self-end flex flex-col gap-1 items-center justify-center w-[102px] h-full cursor-pointer z-[1000] after:bg-primary-orange-100
        } after:absolute after:inset-0 after:origin-left after:scale-x-0 after:transition-transform ease duration-[0.5s] hover:after:scale-x-100  bg-primary-orange
        }`}
            onClick={() => setToggle(!toggle)}
          >
            <span
              className={`w-[30px] h-[3px] bg-white z-10 transition-all ease duration-[0.3s]  ${
                toggle ? "rotate-45 translate-y-[7px]  " : ""
              } `}
            />
            <span
              className={`w-[30px] h-[3px] bg-white z-10 transition-all ease duration-[0.3s] ${
                toggle ? "translate-x-[100%] opacity-0" : ""
              } `}
            />
            <span
              className={`w-[30px] h-[3px] bg-white z-10 transition-all ease duration-[0.3s] ${
                toggle ? "rotate-[-45deg] translate-y-[-7px]" : ""
              } `}
            />
          </div>
        </div>
        {toggle && (
          <motion.div
            initial={{ x: 1000 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.4 }}
            className="absolute top-0 flex flex-col  justify-center itmes-center px-[15px] md:px-[100px] py-[37px] gap-7 h-[100dvh] right-0 w-[100vw] bg-primary-blue z-[999]"
          >
            <Link
              to="/about-us"
              className="navbar-menu "
              onClick={() => setToggle(!toggle)}
            >
              About
            </Link>
            <Link
              to="/services"
              className="navbar-menu "
              onClick={() => setToggle(!toggle)}
            >
              Services
            </Link>
            <Link
              to="/contact-us"
              className="navbar-menu "
              onClick={() => setToggle(!toggle)}
            >
              Contact
            </Link>
            <div className="pt-[30px]">
              <div className="flex flex-col gap-2 mb-[25px]">
                <h3 className="md:text-[45px] text-[24px] text-primary-orange font-extrabold font-grotesk lg:max-w-[50%]">
                  If you need any information, please contact us. We will
                  promptly answer to your question.
                </h3>
              </div>
              <Link
                to="/contact-us"
                className="w-full text-[25px] text-white pr-[20px] mb-[15px] lg:mb-0 "
              >
                <button
                  onClick={() => setToggle(!toggle)}
                  className="  mx-auto p-3 md:p-5 font-bold min-w-[250px] rounded-[45px] bg-primary-orange text-center border border-primary-orange hover:bg-primary-blue  transition-all ease-in-out duration-200"
                >
                  CONTACT
                </button>
              </Link>
            </div>
          </motion.div>
        )}
      </header>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default NavBar;
