import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [send, setSend] = useState(false);
  const sendSubmit = (e) => {
    if (
      form.email === "" ||
      form.message === "" ||
      form.name === "" ||
      form.number === ""
    )return;
      

    setSend(true);
    e.preventDefault();

    setLoading(true);

    setVerified(true);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: form.name,
          to_name: "Rami",
          from_email: form.email,
          to_email: "rami-lash@outlook.com",
          message: form.message,
          from_number:form.number
        },
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        () => {
          setLoading(false);
          setTimeout(function () {
            setSend(false);
          }, 1600);

          setForm({
            name: "",
            email: "",
            message: "",
            number: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error.text);

          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!verified) return;
  };

  

  return (
    <form className="mt-7 z-[10]" ref={formRef}>
      <input
        className="contact-input"
        placeholder="Name"
        required
        type="text"
        name="name"
        value={form.name}
        onChange={handleChange}
      />
      <input
        className="contact-input"
        placeholder="Email"
        required
        type="email"
        name="email"
        value={form.email}
        onChange={handleChange}
      />
      <input
        className="contact-input "
        placeholder="Phone"
        type="number"
        name="number"
        required
        value={form.number}
        onChange={handleChange}
      />
      <textarea
        className="contact-input pb-[80px] h-[100px] overflow-hidden resize-none"
        placeholder="Your request"
        name="message"
        required
        type="text"
        value={form.message}
        onChange={handleChange}
      />
      <button
        className={`form-btn ${send ? "active-btn" : ""}`}
        type="submit"
        onClick={sendSubmit}
      >
        <span className={`${send ? "active-span" : ""} text-[1rem] md:text-[1.3rem]` }>Send</span>
        <svg
          className={send ? "active-svg" : ""}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 11c2.761.575 6.312 1.688 9 3.438 3.157-4.23 8.828-8.187 15-11.438-5.861 5.775-10.711 12.328-14 18.917-2.651-3.766-5.547-7.271-10-10.917z" />
        </svg>
      </button>
    </form>
  );
};

export default Contact;

