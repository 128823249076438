import Hero from "./Hero";
import About from "./About";
import Services from "./Services";
import Consultation from "./Consultation";
import Banner from "./Banner";
import Footer from "./Footer";
import { ScrollRestoration } from "react-router-dom";

const Home = () => {
  return (
    <>
    <ScrollRestoration/>
      <Hero />
      <About />
      <Banner />
      <Services />
      <Consultation />
      <Footer/>
    </>
  );
};

export default Home;
