import bg from "../assets/contact-us-bg.png";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";
import Contact from "../components/Contact";
import {Link} from "react-router-dom";
import { useEffect} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import { ScrollRestoration } from "react-router-dom";

const ContactUs = () => {
 
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      const tl = gsap.timeline();
          tl.from(".gsap-contactPage-text", {
            opacity: 0,
            xPercent: -100,
            ease: `power4.inout`,
          })
            .from(".gsap-contact-form", {
              opacity: 0,
              xPercent: 100,
              ease: `power4.inout`,
            })
            .from(
              ".gsap-contact-img",
              {
                opacity: 0,
                xPercent: 100,
                ease: `power4.inout`,
              },
              `-=0.2`
            );
    });
    return () => ctx.revert();
  }, []);
  return (
    <>

      <section className="gsap-contact-page lg:h-[100dvh] max-w-[100vw]  lg:flex overflow-hidden ">
        <ScrollRestoration/>
        <div className="gsap-contactPage-text lg:w-[50%] flex lg:items-center md:justify-center pl-[15px] pt-[25px] lg:pt-0 pb-[15px] lg:pb-0 lg:ml-0 lg:px-auto">
          <div className="flex flex-col lg:items-center gap-4 ">
            <h1 className=" text-primary-orange text-[35px] lg:text-[35px] mb-8 font-bold font-grotesk self-start">
              Contact Us
            </h1>

            <h2 className=" text-[30px] lg:text-[45px] font-extrabold font-grotesk mb-8">
              HAPPY TO ANSWER ALL <br />
              YOUR QUESTIONS
            </h2>
            <div className="grid grid-cols-2 gap-3 md:gap-8  w-full">
              <div className="flex flex-col gap-3">
                <h3 className="contact-title">Adress</h3>
                <p className="contact-text">Ajman, UAE</p>
              </div>
              <div className="flex flex-col gap-3 ">
                <h3 className="contact-title">Email</h3>
                <Link
                  to="mailto:Rami@blacksmith-uae.com"
                  className="contact-text"
                >
                  Rami@blacksmith-uae.com
                </Link>
              </div>
              <div className="flex flex-col gap-3">
                <h3 className="contact-title">Contact</h3>
                <Link to="tel:0529934946" className="contact-text">
                  0529934946
                </Link>
              </div>

              <div className="flex flex-col gap-3  ">
                <h3 className="contact-title">Social</h3>
                <div className="flex gap-3 text-primary-orange">
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/blacksmithuae"
                  >
                    <BsInstagram className="text-2xl cursor-pointer hover:text-gray-500 transition-all ease duration-[0.3s] " />
                  </Link>
                  <Link target="_blank" to="https://wa.me/+971529934946">
                    <BsWhatsapp className="text-2xl cursor-pointer hover:text-gray-500 transition-all ease duration-[0.3s]" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[50%] bg-gray-50 h-full">
          <div className=" gsap-contact-form w-full h-full relative flex items-center pl-[15px] pb-[15px] lg:pb-0 md:pl-0 md:justify-center overflow-hidden">
            <img
              src={bg}
              alt="steel-fabrication"
              className="gsap-contact-img absolute"
            />
            <Contact />
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default ContactUs;

