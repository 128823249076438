import React from "react";
import shopliffting from "../../assets/services/Untitled design (2).jpg";
import desk from "../../assets/services/desk_auto_x2 (1).jpg";
import rusted from "../../assets/services/Rusted steel2.jpg";
import customSteelLighting from "../../assets/services/Custom Steel Lighting.jpg";
import handel from "../../assets/services/handel.jpg";
import Consultation from "../../components/Consultation";
import ServicesPage from "../../components/ServicesPage";
import Footer from "../../components/Footer";

const InteriorWorks = () => {
  const services = [
    {
      title: `Shopliffting`,
      text: ` Blacksmith offers cost-effective fitouts and installations. We can
            work with you to organise, manage and complete the installation of
            our standard and custom products. We offer stainless steel,
            Electroplated steel and rusty steel products.`,
      img: shopliffting,
    },
    {
      title: `Stainless Steel Office Furniture`,
      text: `Our dumbwaiters are fabricated with cutting-edge technology and
            modern finishes which becomes an interior design feature altogether.`,
      img: desk,
    },
    {
      title: `Rusty Steel and Stainless Steel Signs`,
      text: ` Our Stainless Steel and Rusty steel signs are extremely popular with
            architects and builders for their durability and high quality
            craftsmanship. Solid Cut Stainless Steel or Fabricated Stainless
            Steel, we can certainly bring your vision to life in a highly
            visible way, because even rusty steel signs feel vintage.`,
      img: rusted,
    },
    {
      title: `Custom Steel Lighting and Chandeliers`,
      text: `Industry meets Modern Luxe - Warm light, hand-forged steel, clean
            lines and big impressions are what make our fixtures so alluring. We
            shape the metal using traditional blacksmithing methods and wire it
            with modern lighting technology.`,
      img: customSteelLighting,
    },
    {
      title: `Custom Stainless Steel handles`,
      text: `A Custom Handle of profiles, shapes and sometimes Special Shaped
            Tubing, can be used as Appliance Handles, Basket Handles, Machine
            Handles, Stainless Steel Handles, Lifting Handles, Pull Handles, and
            more.`,
      img: handel,
    },
  ];
  return (
    <>
      <ServicesPage services={services} />
      <Consultation />
      <Footer/>
    </>
  );
};

export default InteriorWorks;

