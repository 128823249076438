import {Link,outlet} from 'react-router-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
const Consultation = () => {
   useEffect(() => {
     gsap.registerPlugin(ScrollTrigger);

     const ctx = gsap.context(() => {
       gsap.from(`.gsap-contactBanner-text`, {
         opacity: 0,
         xPercent: -100,
         scrollTrigger: {
           trigger: ".contactBanner",
           start: "top center+=30%",

           end: "bottom center",

           toggleActions: "play none none reset",
         },
       });
       gsap.from(`.gsap-contactBanner-button`, {
         opacity: 0,
         xPercent: 100,
         scrollTrigger: {
           trigger: ".contactBanner",
           start: "top center+=30%",
           end: "bottom center",

           toggleActions: "play none none reset",
         },
       });
     });
     return () => ctx.revert();
   }, []);
  return (
    <>
      <div className="contactBanner max-w-[100vw] min-h-[200px] bg-primary-orange flex flex-col gap-[30px] sm:gap-[15px] md:gap-0 md:justify-between items-center lg:flex-row overflow-hidden  ">
        <div className="gsap-contactBanner-text w-full lg:w-[50%] flex justify-center lg:pl-[35px] ">
          <h2 className="text-[20px] lg:text-[30px] text-white font-bold font-grotesk w-full md:w-[70%] ">
            If you need any information, please contact us. We will promptly
            address your inquiries
          </h2>
        </div>
        <Link
          to="/contact-us"
          className="gsap-contactBanner-button w-full md:w-[50%] flex justify-center text:[16px] md:text-[25px] text-white pr-[45px] mb-[15px] lg:mb-0 "
        >
          <button className=" mx-auto p-5 font-bold min-w-[200px] md:min-w-[250px] rounded-[45px] bg-primary-blue text-center border border-primary-blue hover:bg-primary-orange  transition-all ease-in-out duration-200;">
            CONTACT
          </button>
        </Link>
      </div>
    </>
  );
}

export default Consultation