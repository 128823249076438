import logo1 from "../assets/logo3.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
const Banner = () => {
    useEffect(()=>{
            gsap.registerPlugin(ScrollTrigger);

        const ctx = gsap.context(() => {
          gsap.from(`.gsap-banner`, {
            opacity: 0,
            xPercent: -100,
            scrollTrigger: {
              trigger: ".banner",
              start: "top center+=30%",

              end: "bottom center",

              toggleActions: "play none none reset",
            },
          });
          gsap.from(`.gsap-banner-logo`, {
            opacity: 0,
            xPercent: 100,
            scrollTrigger: {
              trigger: ".banner",
              start: "top center+=30%",
              end: "bottom center",

              toggleActions: "play none none reset",
            },
          });
        });
        return ()=> ctx.revert()
    },[])
  return (
    <div className="banner md:max-w-[100vw] md:h-[200px] md:bg-primary-orange md:flex md:justify-between md:items-center hidden overflow-hidden ">
      <div className="w-[50%] flex justify-center  ">
        <h2 className="gsap-banner text-[30px] text-white font-bold font-grotesk pl-[30px] ">
          TRUE QUALITY WILL STAND THE TEST OF TIME.
        </h2>
      </div>
      <div className="w-[50%] flex justify-center items-center ">
        <img
          src={logo1}
          alt="blacksmith-logo"
          className="gsap-banner-logo w-[500px] h-[300px] pr-[20px] mb-[20px]"
        />
      </div>
    </div>
  );
}

export default Banner