import {Link} from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import steelFabrication from "../assets/p-l-img-1.jpg";
import interiorWork from "../assets/interior-work.jpg";
import architectural from "../assets/architectural.jpg";
import kitchen from "../assets/services/commercial kitchen.jpg";
import dumbwaiter from "../assets/services/Dumbwaiter.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
const Services = () => {
   useEffect(()=>{
    gsap.registerPlugin(ScrollTrigger)
    const ctx=gsap.context(()=>{
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: `.gsap-services`,
          start: `top center`,
          end: `bottom center`,
          toggleActions: "play none none reset",
        },
      });
          tl.from(`.gsap-first-service`, {
            opacity: 0,
            yPercent: 50,
            stagger: 0.05,
            ease: `power4.out`,
          })
            .from(`.gsap-second-service`, {
              opacity: 0,
              yPercent: 50,
              stagger: 0.05,

              ease: `power4.out`,
            })
            .from(`.gsap-third-service`, {
              opacity: 0,
              yPercent: 50,
              stagger: 0.05,

              ease: `power4.out`,
            })
            .from(`.gsap-fourth-service`, {
              opacity: 0,
              yPercent: 50,
              stagger: 0.05,

              ease: `power4.out`,
            })
            .from(`.gsap-fifth-service`, {
              opacity: 0,
              yPercent: 50,
              stagger: 0.05,

              ease: `power4.out`,
            });
    })
    return ()=>ctx.revert()
  },[])
  return (
    <>
      <section className=" gsap-services w-full  flex flex-col items-center bg-white md:mt-[158px] mt-[158px] ">
        <h1 className="gsap-services-text text-[30px] text-primary-orange font-extrabold font-grotesk lg:hidden pb-[32px]">
          OUR WORK
        </h1>
        <div className="lg:flex flex flex-wrap w-full md:gap-12 gap-2 md:gap-0 justify-center  ">
          <div className=" flex flex-col max-md:w-full md:mr-[25px] mr-0 relative">
            <Link
              to="/services/steel-fabrication-work"
              className="gsap-first-service services group "
            >
              <p className="services-p">STEEL FABRICATION WORK</p>

              <img
                src={steelFabrication}
                alt="STEEL FABRICATION WORK"
                className=" services-img "
              />
            </Link>
            <Link
              to="/services/interior-works"
              className={`${
                `md` ? `gsap-third-service` : `gsap-second-service`
              } services group`}
            >
              <p className="services-p">INTERIOR WORKS</p>
              <img
                src={interiorWork}
                alt="INTERIOR WORKS"
                className="services-img "
              />
            </Link>
            <Link
              to="/services"
              className=" gsap-fifth-service bg-transparent lg:flex hidden items-center border border-black justify-between text-black w-[50%] self-start group hover:cursor-pointer  "
            >
              <p className="p-5 font-bold text-[20px] ">View more</p>

              <Link
                to="/services"
                className="relative text-3xl z-10 group-hover:text-white text-primary-orange  border-l h-full p-5 border-black after:bg-primary-orange-100 after:absolute after:inset-0 after:origin-left after:z-[-10] after:scale-x-0 after:transition-transform ease duration-[0.5s] group-hover:after:scale-x-100 group-hover:text-white"
              >
                <AiOutlineArrowRight className="z-[8888]" />
              </Link>
            </Link>
            <div className="gsap-services-text md:absolute md:top-0 md:left-[-44%] lg:inline hidden">
              <h1 className=" md:text-[25px] text-primary-orange font-extrabold font-grotesk ">
                Our work
              </h1>
              <p className="font-bold text-[55px] font-grotesk mt-4 mix-blend-difference text-white z-[99] leading-[55px] tracking-wide">
                BEST PROJECTS WITH <br />
                CONSTRUCTION
              </p>
            </div>
          </div>
          <div className=" flex flex-col lg:pt-[250px] pt-0 max-md:w-full lg:ml-8 md:ml-[-28px] ml-0 ">
            <Link
              to="/services/architectural-metal-work"
              className={`${
                `md` ? `gsap-second-service` : `gsap-third-service`
              } services group mb-[50px]`}
            >
              <p className=" services-p">ARCHITECTURAL METAL WORK</p>
              <img
                src={architectural}
                alt="ARCHITECTURAL METAL WORK"
                className="services-img"
              />
            </Link>
            <Link
              to="/services/commercial-kitchen-equipment-and-health-care-providers-ss-equipment"
              className="gsap-fourth-service services group mb-[50px]"
            >
              <p className="services-p">
                COMMERCIAL KITCHEN EQUIPMENT AND HEALTH CARE PROVIDERS SS
                EQUIPMENT
              </p>
              <img
                src={kitchen}
                alt="COMMERCIAL KITCHEN EQUIPMENT AND HEALTH CARE PROVIDERS SS
              EQUIPMENT"
                className="services-img"
              />
            </Link>
            <Link
              to="/services/commercial-kitchen-equipment-and-health-care-providers-ss-equipment"
              className="services group mb-[50px] md:hidden"
            >
              <p className="services-p">Internal Dumbwaiter Fabrication</p>
              <img
                src={dumbwaiter}
                alt="Internal Dumbwaiter Fabrication"
                className="gsap-fifth-service w-full h-full"
              />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
