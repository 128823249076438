import { useState, useEffect } from "react";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import imageSLider1 from "../assets/slider_img4.jpg";
import imageSLider2 from "../assets/slider_img7.jpg";
import imageSLider3 from "../assets/slider_img6.jpg";
import {Link} from "react-router-dom";

const Slider = () => {
  const [currIndex, setCurrIndex] = useState(0);
  const [toggleImg, settoggleImg] = useState(false);
  const [scrollSlide, setScrollSlide] = useState(false);
  const [sliderData,setSliderData]=useState([
    {
      img: imageSLider1,
      text1: "WE ARE IN ",
      text3: "BUSINESS TO",
      text2: "SOLUTIONS FOR ARCHITECTURAL WORKS",
    },
    {
      img: imageSLider2,
      text1: "WE ARE IN ",
      text3: "BUSINESS TO",
      text2: "STEEL STRUCTURAL DESIGN SOLUTIONS",
    },
    {
      img: imageSLider3,
      text1: "WE ARE IN ",
      text3: "BUSINESS TO",
      text2: "AUTHORITY APPROVAL IN UAE",
    },
  ])


  const sliderImages = [
    {
      img: imageSLider1,
      text1: "WE ARE IN ",
      text3: "BUSINESS TO",
      text2: "SOLUTIONS FOR ARCHITECTURAL WORKS",
    },
    {
      img: imageSLider2,
      text1: "WE ARE IN ",
      text3: "BUSINESS TO",
      text2: "STEEL STRUCTURAL DESIGN SOLUTIONS",
    },
    {
      img: imageSLider3,
      text1: "WE ARE IN ",
      text3: "BUSINESS TO",
      text2: "AUTHORITY APPROVAL IN UAE",
    },
  ];
  
  let slideInterval;
  const prevImg = () => {
    settoggleImg(true);
    setTimeout(() => {
      currIndex === 0
        ? setCurrIndex(sliderImages.length - 1)
        : setCurrIndex(currIndex - 1);

      settoggleImg(false);
    }, 500);
  };
  const nexImg = () => {
    settoggleImg(true);
    setTimeout(() => {
      currIndex === sliderImages.length - 1
        ? setCurrIndex(0)
        : setCurrIndex(currIndex + 1);
      settoggleImg(false);
    }, 500);
  };
  const auto = () => {
    slideInterval = setInterval(nexImg, 5000);
  };

  useEffect(() => {
    auto();
    return () => clearInterval(slideInterval);
  }, [toggleImg]);
  

  
  return (
    <div className="relative h-[90dvh] max-w-[100dvw] flex flex-col overflow-hidden ">
      <div className="h-[30%] w-full bg-white max-lg:hidden">
        <h1
          className={` max-lg:hero-br absolute text-[75px] font-semibold font-grotesk left-[10%] top-[5%]  ${
            toggleImg ? "translate-x-[-1000%]" : "translate-x-0"
          } transition-translate ease duration-500`}
        >
          {sliderImages[currIndex].text1}

          <br />
          {sliderImages[currIndex].text3}
        </h1>
        <span className="inline lg:hidden">
          {sliderImages[currIndex].text3}
        </span>
        <h2
          className={`absolute text-[75px] w-[45%] text-white font-semibold font-grotesk left-[10%] top-[31%] z-40 drop-shadow-lg ${
            toggleImg ? "translate-x-[-1000%]" : "translate-x-0"
          } transition-translate ease duration-500 `}
        >
          {sliderImages[currIndex].text2}
        </h2>
      </div>
      <div
        className={`absolute right-[10%] top-[7%] flex flex-col gap-3 justify-start items-start hidden lg:flex ${
          toggleImg ? "translate-x-[1000%]" : "translate-x-0"
        } transition-translate ease duration-500 `}
      >
        <h1 className="text-[28px] font-bold ">
          Contact us we will promptly <br /> address your inquiries
        </h1>
        <Link to="/contact-us" className=" hero-btn learn-more ">
          <span className="circle" aria-hidden="true">
            <span className="icon arrow"></span>
          </span>
          <span className="hero-button-text">Contact us</span>
        </Link>
      </div>
      <div
        className={`h-[30%] w-full bg-white lg:hidden pt-[55px] ${
          toggleImg ? "translate-x-[-1000%]" : "translate-x-0"
        } transition-translate ease duration-500 `}
      >
        <h1 className="md:text-[40px] text-[30px] font-semibold font-grotesk ">
          {sliderImages[currIndex].text1}
          {sliderImages[currIndex].text3}
        </h1>
        <h1 className="md:text-[40px] text-[30px] font-semibold font-grotesk">
          {sliderImages[currIndex].text2}
        </h1>
      </div>
      <img
      loading="lazy"
        src={sliderImages[currIndex].img}
        alt={sliderImages[currIndex].text1}
        className={`w-[100vw] h-[70%] object-cover  ${
          toggleImg
            ? "transition-opacity ease-in duration-500 opacity-0"
            : "transition-opacity ease-in duration-500 opacity-1"
        } `}
      />
      <div className="absolute flex bottom-0 left-0 bg-primary-orange h-[60px] w-[150px] cursor-pointer ">
        <div
          className="group relative text-white flex items-center justify-center w-full h-full after:bg-primary-orange-100 after:absolute after:inset-0 after:origin-right after:z-0 after:scale-x-0 after:transition-transform ease duration-[0.5s] hover:after:scale-x-100  "
          onClick={prevImg}
        >
          <AiOutlineArrowLeft className=" text-3xl cursor-pointer z-10 group-hover:scale-110 translate-x-0 transition-translate ease duration-[0.3s] origin-right" />
        </div>
        <div
          className="group relative text-white flex items-center justify-center w-full h-full after:bg-primary-orange-100 after:absolute after:inset-0 after:origin-left after:z-0 after:scale-x-0 after:transition-transform ease duration-[0.3s] hover:after:scale-x-100  "
          onClick={nexImg}
        >
          <AiOutlineArrowRight className=" text-3xl cursor-pointer z-10 group-hover:scale-110 translate-x-0 transition-translate ease duration-[0.5s] origin-right " />
        </div>
      </div>
    </div>
  );
};
export default Slider;
