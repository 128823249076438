import mezzanineFloor from "../../assets/services/Mezzanine Floor.jpg";
import parkingSheds from "../../assets/services/Parking Sheds.jpg";
import steelStructureWork from "../../assets/services/Steel Structure Work.jpg";
import fence from "../../assets/services/fence.jpg";
import Consultation from "../../components/Consultation";
import Footer from "../../components/Footer";
import ServicesPage from "../../components/ServicesPage";

const page = () => {
  const services = [
    {
      title: `Mezzanine Floor`,
      text: `A mezzanine floor is a great way to optimize the storage capacity of
            your warehouse or office. Our mezzanine floors can be effectively
            installed into virtually any space from floor to ceiling and will
            fit seamlessly into a warehouse, office, production or retail
            environment.`,
      img: mezzanineFloor,
    },
    {
      title: ` Sheds, Pergola,Parking Sheds`,
      text: ` Blacksmith specializes in the production and distribution of middle
            grade panel and metal furniture. We have provided Customized service
            and production of office furniture such as sofas, coffee tables,
            reception desks, conference desks and many more. Creating an
            efficient and comfortable office environment for all. We will
            continue to offer competitive and honest service to each of our
            customers and cooperative partners in years to come.`,
      img: parkingSheds,
    },
    {
      title: ` Steel Structure Work and Corrugated Sheet`,
      text: ` Our prowess in intricate design and precise execution showcases our
            deep commitment to excellence in the steel fabrication domain.`,
      img: steelStructureWork,
    },
    {
      title: ` Fences`,
      text: ` Our Fences are both functional and decorative, and are manufactured
            as per customers requirement to meet modern security and design.`,
      img: fence,
    },
  ];
  return (
    <>
      <ServicesPage services={services} />
      <Consultation />
      <Footer/>
    </>
  );
};

export default page;
