import ssIndustrialMachinFabrication from "../../assets/services/SS Industrial Machine Fabrication.png";
import dumbwaiter from "../../assets/services/Dumbwaiter.png";
import Consultation from "../../components/Consultation";
import ServicesPage from "../../components/ServicesPage";
import Footer from "../../components/Footer";

const Dumbwaiter = () => {
  const services = [
    {
      title: `Stainless Steel Industrial Machine Fabrication And Services`,
      text: ` Stainless-Steel fabrication is at the heart of most food
            manufacturing equipment and plant builds. Because of the
            complexities of fabricating in stainless for the food industry, a
            premier stainless-steel fabrication company like Blacksmith is also
            able to produce high quality, cost-effective work in a wide range of
            industries.`,
      img: ssIndustrialMachinFabrication,
    },
    {
      title: `Internal Dumbwaiter Fabrication`,
      text: `Our dumbwaiters are fabricated with cutting-edge technology and
            modern finishes which becomes an interior design feature altogether.`,
      img: dumbwaiter,
    },
  ];
  return (
    <>
      <ServicesPage services={services} />
      <Consultation />
      <Footer/>
    </>
  );
};

export default Dumbwaiter;
