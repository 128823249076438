import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  createRoutesFromElements,
  ScrollRestoration,
} from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import ContactUs from "./contact-us/ContactUS";
import About from "./about-us/About";
import Services from "./services/Services";
import InteriorWorks from "./services-pages/interior-works/InteriorWorks";
import ArchitecturalMetalWork from "./services-pages/architectural-metal-work/Architectural-metal-work";
import CommercialKitchen from "./services-pages/commercial-kitchen-equipment-and-health-care-providers-ss-equipment/CommercialKitchen";
import Dumbwaiter from "./services-pages/dumbwaiter-machinery-and-equipment/Dumbwaiter";
import SteelFabricationWork from "./services-pages/steel-fabrication-work/SteelFabricationWork";
import ScrollToTop from "./components/ScrollToTop ";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<NavBar />}>
      <Route index element={<Home />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="about-us" element={<About />} />
      <Route path="services" element={<Services />} />
      <Route path="services/interior-works" element={<InteriorWorks />} />
      <Route
        path="services/architectural-metal-work"
        element={<ArchitecturalMetalWork />}
      />
      <Route
        path="services/commercial-kitchen-equipment-and-health-care-providers-ss-equipment"
        element={<CommercialKitchen />}
      />
      <Route
        path="services/dumbwaiter-machinery-and-equipment"
        element={<Dumbwaiter />}
      />
      <Route
        path="services/steel-fabrication-work"
        element={<SteelFabricationWork />}
      />
    </Route>
  )
);
const page = () => {
  return (
    <>
      <RouterProvider router={router} />
     
    </>
  );
};

export default page;
