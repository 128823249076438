import React from "react";
import balconyRailing from "../../assets/services/Balcony Railing2.jpg";
import bollards from "../../assets/services/Bollards.jpg";
import steelCladding from "../../assets/services/Steel Cladding.jpg";
import ACDuct from "../../assets/services/SS AC Duct.jpg";
import swimmingLadder from "../../assets/services/Swimming Ladder.jpg";
import Consultation from "../../components/Consultation";
import ServicesPage from "../../components/ServicesPage";
import Footer from "../../components/Footer";

const ArchitecturalMetalWork = () => {
  const services = [
    {
      title: ` Handrails, Balustrades and Balcony Railing`,
      text: ` Handrails, Balustrades & Balcony Railing are made of stainless steel
            304/316 grade. Available in polish, brush, mirror, gold plated,
            antique, aluminium and mild steel powder coated finish.`,
      img: balconyRailing,
    },
    {
      title: `Bollards`,
      text: ` Simplicity and function, our Bollard Collection offers a selection
            of basic commercial steel bollards available in various sizes with
            fully welded flat or dome cap and a stainless steel, brushed finish
            or powder coated finish.`,
      img: bollards,
    },
    {
      title: `SS and Steel Cladding`,
      text: `  With assistance of highly driven technical excellence, we offer a
            fine quality stainless steel and aluminium cladding and installation
            service which include exterior cladding, column cladding, fascias.`,
      img: steelCladding,
    },
    {
      title: ` SS AC Duct`,
      text: `  Duct Systems for kitchen exhaust, moisture laden air, fume exhaust,
            special application like hospitals, testing laboratories, highly
            corrosive atmosphere conditions. Our SS Duct as High resistance to
            corrosion from moisture and most chemicals, ability to take a high
            polish. Good formability and weldability.`,
      img: ACDuct,
    },
    {
      title: `  Swimming Pool Rail And Ladder`,
      text: `We offer our stainless steel ladder in different models, with
            polymer non-skid steps and a premium model with stainless steel
            steps that will not fade, crack or break.`,
      img: swimmingLadder,
    },
  ];
  return (
    <>
      <ServicesPage services={services} />
      <Consultation />
      <Footer/>
    </>
  );
};

export default ArchitecturalMetalWork;
