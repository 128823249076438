import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
const About = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".gsap-about-section ",
          start: "top center",
          end: "center center",
          toggleActions: "play none none reset",
          

        },
      });
      tl.from(".gsap-about-title", {
        opacity: 0,
        xPercent: -150,
        ease: "power4",
        duration: 1,
      })
        .from(
          ".gsap-first",
          {
            opacity: 0,
            yPercent: 100,
            xPercent: -30,

            ease: "power4.out",
          },
          `-=0.6`
        )
        .from(".gsap-second", {
          opacity: 0,
          yPercent: 100,
          xPercent: -30,

          ease: "power4.out",
        })
        .from(".gsap-third", {
          opacity: 0,
          yPercent: 100,
          xPercent: -30,

          ease: "power4.out",
        });
        
    });
    return () => ctx.revert();
  }, []);
  return (
    <div className="gsap-about-section lg:h-[100vh] w-full flex flex-col md:items-center md:flex-row overflow-hidden">
      <div className="gsap-about-title md:w-[50%] flex flex-col items-center md:px-[21px] w-full md:py-0 py-9 ">
        <h1 className="text-[35px] font-bold font-grotesk mb-8 text-primary-orange">
          ABOUT US
        </h1>
        <p className=" w-[70%] text-[20px] md:text-[20px] font-semibold lg:leading-loose max-lg:w-full pl-[30px] ">
          BLACKSMITH STEEL FABRICATION was established in 2003.we are in the
          "Fabrication - Supply - Installation" business that offers total
          solutions for architectural works, With just few years in the
          business, Blacksmith has emerged as one of the leading supplier and
          total solution provider in Emirates. Our key position holders are
          having more than 25 years Gulf experience which itself is a great
          asset.We precisely utilize the latest technologies, adhere to the
          latest standards and comply with technical and safety specifications
          of the manufacturers, clients and regulatory authorities.
        </p>
      </div>
      <div className="bg-primary-blue flex h-full md:w-[50%] w-full text-white items-center justify-center md:py-0 py-9  ">
        <div className="w-[50%] flex flex-col gap-7 py-4">
          <div className="gsap-first flex flex-col">
            <p className=" text-primary-orange text-[20px] font-extrabold ">
              01.
            </p>
            <h2 className=" slef-center justify-self-center lg:text-[35px] text-[25px] font-grotesk ">
              Our core mission.
            </h2>
            <p className="text-[18px] text-primary-hover">
              To fulfill and satisfy the needs and requirements of our clients.
            </p>
          </div>
          <div className="gsap-second flex flex-col ">
            <p className="text-primary-orange text-[20px] font-extrabold ">
              02.
            </p>
            <h2 className="slef-center justify-self-center lg:text-[35px] text-[25px] font-grotesk">
              A flexible and skilled organization.
            </h2>
            <p className="text-[18px] text-primary-hover">
              We can manufacture the most complex custom-made items, even in
              very small batches.
            </p>
          </div>
          <div className="gsap-third flex flex-col ">
            <p className="text-primary-orange text-[20px] font-extrabold ">
              03.
            </p>
            <h2 className="slef-center justify-self-center lg:text-[35px] text-[25px]  font-grotesk">
              Customer satisfaction.
            </h2>
            <p className="text-[18px] text-primary-hover">
              Our mission to make our Customer satisfid.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
