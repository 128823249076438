import {Link} from "react-router-dom";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";


const Footer = () => {
  return (
    <footer className="max-w-[100dvw] min-h-[50dvh] bg-primary-blue overflow-hidden flex flex-col items-start justify-between px-[15px] sm:px-[75px] md:px-[150px] lg:px-[250px]  ">
      <div className="flex w-full text-white justify-between md:justify-around pt-[90px] ">
        <ul>
          <li className=" footer-list-title">Company</li>
          <li className="footer-list pb-3">
            <Link to="/">Home</Link>
          </li>
          <li className="footer-list pb-3">
            <Link to="/about-us">About</Link>
          </li>
          <li className="footer-list pb-3">
            <Link to="/services">Services</Link>
          </li>
          <li className="footer-list">
            <Link to="/contact-us">Contact</Link>
          </li>
        </ul>

        <ul className="hidden md:inline">
          <li className=" footer-list-title">Contacts</li>
          <li className="footer-list pb-3">
            <Link to="mailto:Rami@blacksmith-uae.com">
              Email: Rami@blacksmith-uae.com
            </Link>
          </li>
          <li className="footer-list">
            <Link to="tel: 0529934946">Phone: 0529934946</Link>
          </li>
        </ul>

        <ul>
          <li className="footer-list-title">Social</li>
          <div className="flex gap-4 text-primary-hover">
            <Link
              target="_blank"
              to="https://www.instagram.com/blacksmithuae"
            >
              <BsInstagram className="text-2xl cursor-pointer hover:text-primary-orange transition-all ease duration-[0.3s] " />
            </Link>
            <Link target="_blank" to="https://wa.me/+971529934946>">
              <BsWhatsapp className="text-2xl cursor-pointer hover:text-primary-orange transition-all ease duration-[0.3s]" />
            </Link>
          </div>
        </ul>
      </div>
      <div className=" flex justify-start w-full mt-[30px] md:hidden">
        <ul>
          <li className=" footer-list-title">Contacts</li>
          <li className="footer-list pb-3">Email: Rami@blacksmith-uae.com</li>
          <li className="footer-list">
            <Link to="tel: 0529934946">Phone: 0529934946</Link>
          </li>
        </ul>
      </div>
      <div className="text-white py-[15px] flex justify-center  self-center ">
        <h1>
          &copy; Copyright 2023 {` `}
          <Link
            target="_blank"
            to="https://tamer-autlev-portfolio.com/"
            className="hover:text-primary-orange transition-all ease-out duration-200"
          >
            Tamer-Mohamad
          </Link>
          .All Rights Reserved
        </h1>
      </div>
    </footer>
  );
};

export default Footer;
